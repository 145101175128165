<template>

<div class="modifyFlow" label-width="120px">
    <el-form :model="form" :relus='relus' ref="form">
        <el-form-item label="增减真实已用流量">
            <el-input v-model="form.flowSize" prop="flowSize"></el-input>
            <p class="red">正数为增加流量，负数为减少流量，单位为MB</p>
        </el-form-item>
        <el-form-item label="备注">
            <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item>
             <el-button type="primary" class="themed-button" @click="sure('form')">确定</el-button>
            <el-button type="primary" class="themed-button" @click="close">关闭</el-button>
        </el-form-item>
    </el-form>
</div>

</template>

<script type="text/ecmascript-6">

import { modifyFlow } from "@/api/cardManagement/cardBillDetail.js";
import { messageBox } from "@/utils/common.js";

export default {
    props:['curInfor','cardId'],
    data(){
        return {
            form:{
                flowSize:0,
                remark:''
            },
            relus:{
                flowSize:[
                    {require:true,massage:'请输入真实流量',trigger:'blur'}
                ]
            }
        }
    },
    methods:{
        sure(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this._modifyFlow()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
            
        },
        close(){
           this.$emit('close') 
        },
        _modifyFlow(){
            let params ={}
            params.cardId = this.curInfor.id
            params.iccidOrPhone = this.curInfor.iccidMark
            params.setMealId = this.curInfor.setMealId
            params.isChild = this.curInfor.isChild
            params.codeChild = this.curInfor.codeChild
            params.flowSize = this.form.flowSize
            modifyFlow(params).then((res)=>{
                this.close()
            })
        }
    }
}
</script>
<style>
.modifyFlow .el-input {  width: auto; }
.modifyFlow .red{color:#f00;width:120px}
</style>