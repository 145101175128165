<template>

<div class="add-package" style="max-height:600px;overflow:auto;">
    <div class="group">
        <h1>叠加包</h1>
        <div>
            <div v-for="(item,index) in packageOne" :key="index">
                <input type="radio" name="packageIds" :value="item.id" v-model="packageIds">
                <span>{{item.name}}</span>
            </div>
        </div>
        
    </div>
    <div class="group">
        <h1>叠加包(语音)</h1>
        <div>
            <div  v-for="(item,index) in packageVoice" :key="index" >
                <input type="radio" name="packageIds" :value="item.id" v-model="packageIds">
                <span>{{item.name}}</span>
            </div>
        </div>
    </div>
    <div class="group">
        <h1>基础套餐</h1>
        <div v-show="showBasic">
             <div  v-for="(item,index) in packageBasic" :key="index" >
                <input type="radio" name="packageIds" :value="item.id" v-model="packageIds">
                 <span>{{item.name}}</span>
                 <span v-show="item.cardCount">({{item.cardCount}})</span>
            </div>
        </div>
    </div>
    <div class="foot">
        <el-button type="primary" class="themed-button" @click="sure">确定</el-button>
        <el-button type="primary" class="themed-button" @click="reset">重置</el-button>
        <el-button type="primary" class="themed-button" @click="close">取 消</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">

import {mealRecharge} from '@/api/cardManagement/cardBillDetail.js'
import dictGroup from '@/mixins/dictGroup.js'

export default {
    props:["addPackageData","curInfor"],
    mixins:[dictGroup],
    data(){
        return {
            showBasic:true,
            packageIds:'',
            packageOne:[],
            packageVoice:[],
            packageBasic:[]

        }
    },
    mounted(){
        this.dateShow()
    },
    methods:{
        sure(){
            if(!this.packageIds){
                return 
            }
            let params ={}
            params.iccidOrPhone = this.curInfor.iccidMark
            params.setMealId = this.curInfor.setMealId;
            params.isChild = this.curInfor.isChild;
            params.codeChild = this.curInfor.codeChild;
            params.packageIds = this.packageIds
            params.countNum = 1
            params.effectType = 1
            mealRecharge(params).then((res)=>{
                this.$alert('充值成功', '提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        this.close()
                    }
                })
                return
            })
        },
        reset(){
            this.packageIds = ""
        },
        close(){
            this.$emit('close')
        },
        dateShow(){
            this.packageOne =[]
            this.packageVoice =[]
            this.packageBasic = []
            let list = this.addPackageData
            if(list && typeof list !=="undefined" && list.length > 0){
                var cardSecondCode = "";
                list.forEach((item) => {
                        if(item.id===this.curInfor.setMealId && item.secondIsChild === "2") {
                            cardSecondCode = item.secondCodeChild;
                            return;
                        }
                    }
                );
                list.forEach((item)=>{
                    if(item.type == this._getDictValue('DJB_',this.w_packageType)){
                        this.packageOne.push({id:item.id,name:item.name})
                    }else if(item.type == this._getDictValue('YYDJB_',this.w_packageType)){
                        console.log(item.id)
                        this.packageVoice.push({id:item.id,name:item.name})
                    }else {
                        let setMealId = this.curInfor.setMealId;
                        let isChild = this.curInfor.isChild;
                        let codeChild = this.curInfor.codeChild;
                    
                        if(cardSecondCode && cardSecondCode!=="") {
                            if(item.secondCodeChild===cardSecondCode) {
                                console.log(item.id)
                                this.packageBasic.push({"id":item.id,name:item.name,cardCount:item.cardCount})
                            }
                        } else {
                            if(isChild=="1"){
                                if(item.id==setMealId){
                                    console.log(item.id)
                                    this.packageBasic.push({id:item.id,name:item.name,cardCount:""})
                                }
                            }else{
                                this.showBasic = false
                                console.log(item.id)
                                if(item.codeChild==codeChild){
                                    this.packageBasic.push({id:item.id,name:item.name,cardCount:item.cardCount})
                                }
                            }
                        }

                    }
            });

            }

        }
    }
}
</script>

<style>
.add-package .foot{text-align: right;}
</style>
